import React from 'react'
import { Link } from 'react-router-dom'
import thumb from '../images/donnie-ashok.jpg'
import Footer from '../parts/footer'
import Nav from '../parts/nav'

export default function About() {
  return (
    <div className="w-screen h-screen p-8 font-mono leading-relaxed bg-dark">
      <div className="flex flex-col w-full h-full bg-dim">
        <Nav />
        <div className="h-full overflow-hidden">
          <div className="flex h-full">
            <div className="px-4 mx-auto my-auto md:w-2/3">
              <h1 className="text-xl uppercase text-light md:text-2xl">
                About me
              </h1>
              <div className="flex mt-6">
                <div className="w-24 pr-4">
                  <img className="rounded-full" alt="Donnie Ashok" src={thumb} />
                </div>
                <div className="flex-grow w-2/3 pl-4 ">
                  <div className="text-sm text-gray-400 md:text-base">
                    <span>
                      I am a Senior Infrastructure Engineer working at&nbsp;
                    </span>
                    <a href="https://aleph-alpha.com/" target="_blank" rel="noopener noreferrer"><strong>Aleph Alpha</strong></a>
                    <span>
                      . I live in Berlin, Germany.
                    </span>
                    <br />
                  </div>
                  <div className="mt-4 lg:flex">
                    {/*
                    <div className="mb-4">
                      <Link className="px-6 py-2 m-1 mr-2 text-xs text-white border-2 bg-dull" to="/bio">My Work</Link>
                    </div>
                    */}
                    <div>
                      <Link className="px-6 py-2 m-1 text-xs text-white border-2 bg-dull" to="/resume">See Resume</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
